import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'
import ThanksComponent from '../../components/ThanksComponent';

const NoahCovenant = () => {

  const navigate = useNavigate();

  // const [search, setSearch] = useState('')


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 24;
    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      //     console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  return (

    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">

          <div className='chapter_heading2'>
            <p> НУҲ (А.С.) БИЛАН ҚИЛИНГАН  </p>
            <p>АҲД</p>
          </div>

          <div className='decor_line'>
            <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
          </div>

          <div className='main_text'>
            <p className='boldItalic center'>«Эй Нуҳ, ... кемадан тушгин» <span className='vitalic center'>(Ҳуд сураси 11:48).</span>
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <p className=' '> Тўфон сувлари тарқаб, ер қуригандан сўнг
              Оллоҳнинг расули оиласи билан кемадан чиқдилар
              ва ҳалол ҳайвон ва қушларнинг ҳаммасидан битта-битта сўйиб, Худога қурбон қилдилар.</p>

            <p>
              Ўшанда Оллоҳ таоло: «Инсон ёшликдан гуноҳ қилишга мойил. Шундай бўлса ҳам Мен энди ерни бошқа бундай лаънатламайман — ҳамма тирик мавжудотни нобуд қилмайман.
              Токи дунё бор экан, экиш ва йиғиш, иссиқ ва совуқ, ёз билан қиш, кеча билан кундуз доим давом этади», деди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}


            >
              <p>
                <sup>1</sup>   Имом Бухорий ва Муслимда «Оллоҳ Одамни Ўз суратида яратди», деган гап бор. Биз бу ҳақда китобнинг бош саҳифаларида айтган эдик. Хўш, бу жумлани қандай тушуниш мумкин? Инсон махлуқ бўлса, Оллоҳ холиқ бўлса, биз нахотки Худога ўхшаш бўлсак? Биз нокомилмиз, нуқсонлимиз, айбларимиз кўп. Қуръонда «У зотга ўхшаш ҳеч нарса йўқдир», деб ёзилган (42:11). Лекин ўхшашлик мукаммал бўлиши шартми? Имом Ғаззолий мана шу мавзу ҳақида гапириб дейди: «Инсоннинг ҳаёт мақсади асмаул-ҳуснанинг маъноларида ўз аксини топган. Инсон бу гўзал исмларга тақлид қилади, улар билан ўз хулқини безашга интилади, имконият даражасида бу исмларга лойиқ бўлишга ҳаракат қилади, токи Ҳаққа яқинроқ бўлса»..
              </p>
            </div>
            <p>
              Кейин Нуҳ (а.с.) билан унинг ўғилларини баракалаб: «Самарали бўлинглар. Кўпайиб, ер юзини тўлдиринглар! — деди. — Ердаги каттаю кичик ҳайвонлар, осмондаги қушлару сувдаги балиқлар сизлардан қўрқади. Яшил ўсимликлар каби, энди улар ҳам сизнинг ихтиёрингизда.
              Лекин ҳайвонларни қони билан еманглар. Чунки қонда ҳаёт бор.
            </p>

            <p onClick={() => handleSupClick(1)}>
              Мен сизларга одам ўлдиришни маън қиламан. Инсон қонини тўккан Менга ҳисоб беради. Чунки Мен инсонни Ўз суратимда<sup>1</sup> яратдим. Инсонни ўлдирган одам ҳам, ҳайвон ҳам ўлим жазосига тортилсин.
            </p>

            <p>
              Мен сизлар ва сизларнинг авлодларингиз билан,
              шунингдек, кемадан чиққан ҳамма жониворлар — қушу ҳайвонлар билан аҳд тузаман.
              Энди ҳеч қачон ер юзидаги ҳамма жонзодларни сув тўфони билан нобуд қилмайман. Ер юзини нобуд қиладиган бундай тўфон бошқа содир бўлмайди».
            </p>

            <p>
              Оллоҳ таоло сўзини давом эттириб деди:

              «Мен булутлар орасига Ўз камалагимни қўяман. Ана шу камалак Менинг сизлар билан ҳамда барча жонли махлуқлар билан қилган аҳдимнинг аломатидир.   Қачон осмонда булут тўпланса ва булутлар орасида камалагим кўринса,
              Мен сизлар билан қилган аҳдимни эслайман. Ҳаётни бутунлай нобуд қиладиган бундай тўфон бошқа такрор бўлмайди.
              Қачон булутларда камалак кўринса, Мен унга қараб ер юзида яшаётган ҳамма жонли махлуқлар билан абадий аҳд тузганимни хотирлайман.
              Мана шу нарса Менинг ер юзидаги ҳамма тирик жонзодлар билан қилган аҳдимнинг аломати», деди.
            </p>

            <p className="vitalic">
              Энди осмонда камалак кўринса, мана шу воқеалар эсингизга тушса керак. Унутманг, Оллоҳ бизнинг ҳалок бўлишимизни эмас, балки нажот топишимизни истайди. Нажот йўлини изланг. Нуҳ (а.с.) нинг қавмига ўхшаб ҳаётингизга бепарво бўлманг.
            </p>
            <p>
            </p>
          </div>
          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}


            >
              <p>
                <sup>2</sup> Ибн Қутайба, “Маориф”; Масъудий, “Муруж уз-заҳаб”.
              </p>
              <p>
                <sup>3</sup>  Анкабут сураси 14-оятга қаранг.
              </p>
            </div>
            <p onClick={() => handleSupClick(3)}>
              Нуҳ (а.с.) тўфондан кейин яна уч юз эллик<sup>2</sup> йил умр кўриб,  тўққиз юз эллик ёшга чиқиб вафот этадилар.<sup>3</sup>
            </p>
            <p></p>
          </div>



          <div className='paragraph_heading'>
            <p>  НУҲ АЛАЙҲИС-САЛОМНИНГ АВЛОДЛАРИ </p>
          </div>

          <div className='main_text'>
            <p className="boldItalic">
              «Дарҳақиқат, Нуҳ ва Иброҳимни Пайғамбар қилиб юбордик ҳамда пайғамбарликни ва китобни икковларининг зурриятига хос қилдик» <span className="vitalic">(Ал-Ҳадид 57:26).</span>
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[4] ? 'block' : 'none' }}
              onClick={() => handleSupClick(4)}
            >
              <p>
                <sup>4</sup> Ибн Абди Раббиҳ, “Иқд ул-фарид”, 3-жилд, 405-бет.
              </p>
            </div>
            <p onClick={() => handleSupClick(4)}>
              <span className="vitalic">Юқорида айтилганидек, </span> ҳазрати Нуҳнинг учта ўғли тўфонда омон қолган: Сом, Ҳом ва Ёфас (р.а.).<sup>4</sup> Ҳозир ер юзида яшаётган ҳамма одамлар  Нуҳ (а.с.) нинг ана шу учта ўғлидан тарқаган.<sup>5</sup>
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[4] ? 'block' : 'none' }}
              onClick={() => handleSupClick(4)}
            >
              <p>
                <sup>5</sup>  Нуҳ (а.с.) нинг бир ўғли тўфонда ҳалок бўлган. Қуръонда айтилишича, у: «Тоққа жойлашиб олсам, мени сувдан омон сақлайди», дейди. Нуҳ пайғамбар эса: «Бугун Оллоҳнинг амридан сақловчи йўқ. Магар кимни раҳм қилсагина сақлар», деб уни кемага киришга даъват қиладилар. Шунда ораларини тўлқин тўсиб ўғли сувга ғарқ бўлади (Ҳуд сураси 11:43).
              </p>
            </div>

            <p>
              Ёфас (р.а.) дан Ҳомар, Маъжуж, Мадай, Ёвон, Тувол, Мушак ва Тирос деган фарзандлар дунёга келди.
              Ҳомардан Ашкиноз, Рифат ва Тўжарма,
              Ёвондан эса Aлишa ва Тарший деган ўғиллар, китийлар ва роданийлар авлоди туғилди.
            </p>

            <p>
              Ҳом (р.а.) эса Куш, Мисройин, Пут ва Канъон деган ўғиллар кўрди.
              Кушдан Сабаъ, Ҳавила, Савта, Раъма, Намруд ва Сабтака деган ўғиллар туғилди. Раъмадан Шеба ва Дедан туғилди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[6] ? 'block' : 'none' }}
              onClick={() => handleSupClick(6)}

            >
              <p>
                <sup>6</sup>   Расан шаҳри Найнаво билан Калах орасида жойлашган эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(6)}>
              Намруд — овчиликда донғи чиққан қаҳрамон —
              Шинор юртига — Бобил, Арак, Акад ва Кална шаҳарларини ўз ичига олган катта ҳудудга подшоҳлик қилган.
              У киши кейин Ошурга бориб, Найнаво, Раҳобот-ир, Калах ва Расан<sup>6</sup>    шаҳарларини барпо қилган.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <p>
              Мисройиндан лудийлар, анамийлар, лаҳобийлар, нафтуҳийлар,
              патрусийлар, кафтўрийлар ва (филистийларнинг аждоди бўлган) каслуҳийлар вужудга келган.
              Канъондан эса Сайдун ва Ҳит туғилган,
              ябусийлар, амўрийлар, жаржосийлар,
              ҳавийлар, арқийлар, синийлар,
              арводийлар, саморийлар ва ҳамотийлар пайдо бўлган.
            </p>

            <p>
              Канъондан пайдо бўлган бу қабилалар кейин Сайдундан Жарор тарафдаги Ғазагача, бу ёқда эса Садўм, Ғамўра, Адма, Зебойим ва Лаша шаҳарларича бўлган ҳудудни эгаллаган.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[7] ? 'block' : 'none' }}
              onClick={() => handleSupClick(7)}


            >
              <p>
                <sup>7</sup> Ушбу насабнома Ибн Исҳоқнинг “Сира” китобида, ҳамда бошқа кўп матнларда, масалан, Ибн Соднинг “Табақот” ида, Табарийда, Ибн Қутайбада, Масъудий ва Ибн Асокирларда ҳам келган.
              </p>
            </div>
            <p onClick={() => handleSupClick(7)}>


              Сом (р.а.) дан эса Элам, Ошур, Арфаҳшод, Луд ва Арам деган ўғиллар туғилди.<sup>7</sup> Арамдан Ус, Ҳул, Жатар ва Маш,
              Арфаҳшоддан эса Шалаҳ деган ўғил дунёга келди. Шалаҳдан Абр туғилди.
              Абрнинг икки ўғли бор эди. Биринчисига Фалағ (яъни бўлиниш) деб от қўйди. Чунки ер юзидаги инсонлар ўша пайтда бўлинган. Иккинчи ўғлининг оти Йўктан эди.
              Йўктандан Алмўдод, Шалаф, Ҳазармавт, Яроҳ,
              Ҳадуром, Узал, Дикло,
              Ўбол, Абумоил, Шебо,
              Ўфир, Ҳавила ва Йўбоб деган фарзандлар туғилди.
              Улар Мешадан то шарқдаги Сефор қирликларигача бўлган минтақада умр сурганлар.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[8] ? 'block' : 'none' }}
              onClick={() => handleSupClick(8)}


            >
              <p>
                <sup>8</sup> Иброҳим (а.с.) нинг отаси яна Озар исми билан ҳам машҳур бўлган (Анъом сураси 74).
              </p>
            </div>
            <p onClick={() => handleSupClick(8)}>
              Фалағдан Арғу (ёки Рау),
              Арғудан Саруғ,
              Саруғдан Ноҳур туғилди.
              Ноҳурдан эса кейинчалик Иброҳим алайҳис-саломнинг отаси бўлган Торуҳ<sup>8</sup> дунёга келди.
            </p>
            <p></p>
          </div>

          <article className='nav_arrows'>
            <div className='flex_it space_between'>
              <div style={{ marginLeft: '0px' }} onClick={() => navigate(-1)}>
                <div>
                  <span className='left_arrow_span'>&#8592;</span>
                </div>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' > орқага қайтиш </span>
                </div>
              </div>

              <div className='central_div'>
                <div>
                  <p>Нуҳ (а.с.) билан аҳд </p>
                </div>
              </div>

              <div style={{ marginRight: '0px' }} onClick={() => navigate('/qisas-uzbek/abrahamic-story')}>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' >  Иброҳим (а.с.)</span>
                </div>
                <div>
                  <span className='right_arrow_span' >&#8594;</span>
                </div>
              </div>
            </div>
          </article>
          {/* <ThanksComponent /> */}
        </main>
        <aside>
        </aside>
      </div>
    </div>
  )
}

export default NoahCovenant
