import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'
import ThanksComponent from '../../components/ThanksComponent';

const DeathOfFirstborn = () => {

  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 14;

    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      // console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  return (

    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">
          <div className='chapter_heading2'>
            <p> ҚУРБОНЛИК БАЙРАМИ, Тўнғичлар </p>
            <p>ўлими</p>
          </div>

          <div className='decor_line'>
            <p>
              &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;
            </p>
          </div>



          <div className='main_text'>
            <p>
              Шундан сўнг Худо Мусо ва Ҳорун (а.с.) га бундай деб амр қилади: «Мана шу ой сизлар учун йилнинг биринчи ойи бўлсин. Халққа айт: шу ойнинг ўнинчи куни ҳар бир эркак ўз оиласи учун битта қўй ё эчки олсин. Агар бир қўй оилага кўплик қилса, оила узвларининг сонига қараб энг яқин ҳамсояси билан бирга тақсим қилсинлар.
            </p>
            <p>
              Ҳайвон танлашда эҳтиёт бўлинг, у бенуқсон, бир яшар ва эркак бўлсин. Ойнинг ўн тўртигача уни парвариш қилиб, ўн тўртинчи кун шом пайтида сўясизлар. Қонини эса, гўшт ейиладиган хонадоннинг эшиги атрофига суртасиз.
            </p>
            <p>
              Қурбонлик гўштининг ҳаммасини ўша кеча оловда қовуриб, аччиқ ўтлар ва хамиртурушсиз ёпилган нон билан ейсизлар. Уни хомлигича ё сувга қайнатиб тановул қилманглар. Пиширилган гўшт эрталабгача қолмасин. Мабодо ортиб қолса, оловда куйдиринглар.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p >
                <sup>1</sup> Тавротда ҳайвонларнинг ўлими ҳам тилга олинган.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)}>
              Гўштни этагингиз йиғилган, ҳассангиз қўлингизда, чориқларингиз оёғингизда — шошилиб ейсизлар. Бу — ворисларга нажот берувчи қурбонликдир. Ўша кеча ўлим фариштаси Мисрни айланиб чиқади. Мисрликларнинг тўнғич ўғилларини ўлдиради.<sup>1</sup>
            </p>
            <p>
              Мисрнинг ҳамма худолари шарманда бўлади. Чунки Мендан бошқа худо йўқ. Эшикларингизга суртилган қон сизлар яшайдиган уйларнинг нишонаси бўлади. Чунки қонни кўргач, ажал ўша уйни четлаб ўтади. Ўлим сизларга зарар етказмайди. Ҳа, бу кун сизлар учун хотира куни бўлади. Уни Оллоҳнинг байрами сифатида ҳар доим нишонланглар».
            </p>
          </div>


          <div className='paragraph_heading'>
            <p> ХАМИРТУРУШСИЗ НОН БАЙРАМИ </p>
          </div>

          <div className='main_text'>
            <p>
              «Етти кун хамиртурушсиз нон ейсизлар. Биринчи кундан хамиртурушни уйларингиздан йўқотинг. Кимки бу етти кун ичида хамиртуруш солинган нон еса, Исроил халқи орасидан ҳайдалсин. Биринчи ва еттинчи куни жамоа биргаликда йиғин ўтказасизлар. Бу кунларда таом тайёрлашдан бошқа ҳеч қандай иш қилманглар.
            </p>
            <p>
              Ҳар йили бир марта хамиртурушсиз ёпиладиган нон байрамини нишонланглар, чунки Мен ана шу куни сизларни Мисрдан олиб чиқаман. Бу кунни байрам қилиш сизлар учун мангулик амр бўлсин. Биринчи ойнинг ўн тўртинчи куни оқшомидан бошлаб йигирма биринчи куннинг оқшомигача нонни хамиртурушсиз ейсизлар.
            </p>

            <p>
              Бу етти кун ичида уйларингизда хамиртурушдан асар ҳам бўлмасин. Хамиртурушли бирон нарса еган одам, хоҳ ўзларингиздан бўлсин, хоҳ бошқа миллатдан, Исроил жамоасидан ҳайдалади. Хамиртуруш солинган ҳеч нарса еманглар. Қаерда бўлишингиздан қатъи-назар хамиртурушсиз нон ейишингиз керак».
            </p>
          </div>


          <div className='paragraph_heading'>
            <p> “Оллоҳ ҚУРБОНЛИК ТУФАЙЛИ БИЗНИ АСРАБ ҚОЛДИ” ДЕЙСИЗЛАР </p>
          </div>

          <div className='main_text'>
            <p>
              Кейин ҳазрати Мусо Исроилнинг ҳамма оқсоқолларини олдига жамлаб бундай дедилар: «Бориб ҳаммага айтинглар, ҳар бир оила қурбонлик учун қўй танлаб, ўн тўртинчи куни сўйсин. Қонини тоғорага олинглар. Кейин бир даста зуфо ўти билан тоғорадаги қонни эшикларнинг атрофига суртинглар. Эрталабгача ҳеч ким уйидан чиқмасин. Ўлим фариштаси мисрликларни ўлдириш учун келганда суртилган қонни кўриб, уйларимизни четлаб ўтади.
            </p>
            <p>
              Бу маросимни биз ҳам, фарзандларимиз ҳам ҳамиша амр сифатида асраб-авай­лашимиз лозим. Оллоҳ ваъда қилган ерга киргандан кейин ҳам унга риоя қилинглар.
            </p>
            <p>
              Фарзандларингиз сиздан: “Бу ибодатнинг маъноси нима?” деб сўрайди. Шунда айтасизлар: “Бу — Оллоҳ учун қилинадиган Қурбонлик Ибодати. Худо ўлим фариштасини юборганида, уйларимизни четлаб ўтиб, бизни асраб қолган”, дейсизлар».
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ТЎНҒИЧ ЎҒИЛЛАРНИНГ ЎЛИШИ </p>
          </div>

          <div className='main_text'>
            <p>
              Одамлар Мусо (а.с.) нинг сўзларини эшитгач Оллоҳ таолога сажда қилиб, шукрона айтдилар. Кейин бориб, берилган ҳамма буйруқларни бекаму кост бажаришди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}
            >
              <p >
                <sup>2</sup> Биринчи туғилган эркак ҳайвонлар ҳам нобуд бўлган эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(2)}>
              Ниҳоят, айтилган кун ҳам келди. Ярим кечада Миср подшоҳи фиръавннинг тўнғич ўғлидан бошлаб, зиндондаги асирларнинг тўнғичигача ҳаммаси ўлди. Фиръавннинг уйида ҳам, унинг аъёнлари уйида ҳам, камбағаллар кулбасида ҳам ҳамма оёққа туриб кетди. Бутун Миср бўйлаб фарёд кўтарилди. Чунки ўлим кўрмаган биронта ҳам хонадон йўқ эди.<sup>2</sup>
            </p>
            <p className="boldItalic">
              «Батаҳқиқ, Биз унга оят-мўъжизаларимизнинг ҳаммасини кўрсатдик. Бас, у ёлғонга чиқарди ва бош тортди» <span className="vitalic">(Тоҳо 20:56).</span>
            </p>

            <p className="vitalic">
              Ҳа, ўша куни ажал фариштаси фақат мўминларнинг уйинигина четлаб ўтган эди. Чунки улар Оллоҳга ва Унинг расулига иймон келтирган, Оллоҳ айтганидек қилиб қурбонликлар сўйган эдилар. Сўйилган қурбонликлар Исроил фарзандларини омон сақлади. Эсингиздами, Иброҳим пайғам­бар­нинг ўғлини ўлимдан нима сақ­ла­ган эди? Худо тайин этган қурбонлик. Ҳар қандай қурбонликми? Йўқ. Ҳар қандай қурбонлик эмас. Худо айтган қурбонлик айбсиз-нуқсонсиз бўлиши керак. Эсингизда бўлса керак, Иброҳим алайҳис-саломга қурбонликни Оллоҳнинг Ўзи етказган эди.
            </p>
            <p>
              Шу кечанинг ўзида фиръавн Мусо (а.с.) билан Ҳорун ҳазратларини чақиртириб: «Бўлди, халқингизни олиб, бу ердан кетинг. Бориб Худойингизга ибодат қилинг. Мол-ҳолларингизни ҳам олиб кетинг. Фақат илтимос, менинг ҳаққимга дуо қилинглар!» деди.
            </p>
            <p>
              Мисрликлар ҳам уларни, тезроқ кетинглар, бўлмаса ҳаммамиз ҳалок бўламиз, деб шошираётган эди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>МИСРДАН ЧИҚИШ</p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}
            >
              <p >
                <sup>3</sup> Шуаро сурасининг 52-оятида: «Биз Мусога: “Бандаларим билан­ кечаси йўлга чиқ. Ле­кин кейин албатта, таъқиб эти­лурсиз”, деб ваҳий юбордик», деб ёзилган.
              </p>
            </div>
            <p onClick={() => handleSupClick(3)}>
              Шундай қилиб ибронийлар хамиртуруш қўшилмаган хамирларини тоғоралари билан кийимларга ўраб, елкаларига кўтардилар. Хотин ва болаларни ҳисобламаганда тахминан олти юз мингга яқин эркак пиёда Раъмасисдан Суккут шаҳрига қараб йўлга чиқди.<sup>3</sup>
            </p>
            <p>
              Йўл бўйи Мисрдан олиб чиққан хамирдан хамиртурушсиз нон ёпдилар. (Мисрдан шошиб чиққанлари учун нон ёпиб олишга уларнинг вақти бўлмаган эди.)
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[4] ? 'block' : 'none' }}
              onClick={() => handleSupClick(4)}
            >
              <p >
                <sup>4</sup> Ўша куни кечаси улар тун бўйи Оллоҳ таолонинг назари остида бўлдилар. Шунинг учун ҳам Исроил халқига ҳар йили шу кечани Яратганга атаб, Яратганни улуғлаб ўтказиш амр қилинган.
              </p>
            </div>
            <p onClick={() => handleSupClick(4)}>
              Исроил халқи Мисрда ҳаммаси бўлиб тўрт юз ўттиз йил яшаган. Тўрт юз ўттиз йилнинг охирги куни ҳаммалари Мисрни тарк этдилар.<sup>4</sup>
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ҚУРБОНЛИК БАЙРАМИ ХУСУСИДА АМРЛАР </p>
          </div>

          <div className='main_text'>
            <p>
              Оллоҳ таоло Мусо пайғамбар орқали Қурбонлик байрами ҳақида яна бир нечта қонун-қоидани нозил қилган эди:
            </p>
            <p>
              «Аҳдга кирмаган одам қурбонлик учун сўйилган гўштдан емасин. Қулларингиз, агар хатна қилинган бўлса, ундан ея олади. Мусофирлар ва ёлланма ишчилар ундан емасин. Қурбонлик гўшти бир уйнинг ичида ейилсин, уйдан ташқарига биронта ҳам бўлаги чиқмасин. Суякларининг биронтаси ҳам синмасин.
            </p>
            <p>
              Бутун Исроил жамоаси бу илоҳий байрамни тажлил қилиши шарт. Орангизда яшаётган бошқа миллат вакиллари байрамда иштирок этишни истаса, аввал уйидаги ҳамма эркакларни хатна қилиши лозим. Шундан кейин у, орангизда туғилган одамлар каби байрамга қатнаша олади. Хатна бўлмаган ҳеч бир эркак қурбонлик гўштидан емасин. Бу қонун сизларга ҳам, орангизда яшаётган бегона халқларга ҳам бирдек тегишли».
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ЙЎЛДА </p>
          </div>

          <div className='main_text'>
            <p>
              «Батаҳқиқ, биз Бани Исроилга китобни, ҳукмни ва пайғамбарликни бердик, уларга пок нарсаларни ризқ қилиб бердик ва уларни оламлар ичида афзал қилдик» (Қуръон 45:16).
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[5] ? 'block' : 'none' }}
              onClick={() => handleSupClick(5)}
            >
              <p >
                <sup>5</sup> Қуртубий, “Тафсир”, 13-жилд, 100-бет.
              </p>
            </div>
            <p onClick={() => handleSupClick(5)}>
              <span className="vitalic"> Шундай қилиб, Худонинг халқи ваъда қилинган юртга қараб йўлга тушди.</span>
              <sup>5</sup>
            </p>
            <p>
              Канъонга бориш учун энг яқин йўл филистийлар ери орқали эди. Лекин Худо уларни у йўлдан олиб бормади. “Агар урушга дучор бўлсалар, пушаймон бўлиб, Мисрга қайтишни хоҳлаб қоладилар”, деб халқни саҳро йўлидан Қизил Денгиз томон олиб кетди.
            </p>
            <p>
              Мусо пайғамбар Юсуф (а.с.) нинг суякларини ҳам ўзи билан олган эдилар. Чунки Юсуф (а.с.) ўлишдан олдин Исроил фарзандларига: «Худо албатта сизларга ёрдам беради. Сизларни Мисрдан олиб чиқади. Ўшанда менинг суякларимни ўзингиз билан олиб кетинг», деб қасам ичирган эди.
            </p>
            <p>
              Бани Исроил Суккут шаҳрини тарк этиб, саҳро четидаги Етомда қароргоҳ қурди. Кечаю кундуз Оллоҳ таоло Ўз халқи билан бирга эди: Оллоҳнинг жалоли кундузи булут устуни кўринишида, кечалари эса олов устуни шаклида уларга нур сочар эди.
            </p>
          </div>


          <div className='paragraph_heading'>
            <p> ФИРЪАВННИНГ ҚУВЛАШИ </p>
          </div>

          <div className='main_text'>
            <p>
              Бани Исроил Мисрни тарк этгандан сўнг, фиръавн ҳам, сарой аъёнлари ҳам: «Нега уларни қўйиб юбордик? Энди ким бизга хизмат қилади?» деб пушаймон бўлдилар.
            </p>
            <p>
              Бу пайтда Бани Исроил Оллоҳнинг амри билан Бол-Сефунга қайтиб келиб, денгиз соҳилида қароргоҳ қурган эди.
            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[6] ? 'block' : 'none' }}
              onClick={() => handleSupClick(6)}
            >
              <p >
                <sup>6</sup> Фиръавн ўзи билан энг сара олти юз жанговар аравасини ҳам олган эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(6)}>
              Фиръавн буни эшитиб: «Улар саҳрода адашиб чиқолмай қолган», деб ўйлади ва жанговар араваларини ҳамда лашкарларини олиб Бани Исроилни таъқиб қилишга тушди.
              <sup>6</sup>
            </p>
            <p>
              Буларнинг ҳаммаси Оллоҳнинг иродаси билан бўлаётган эди. Оллоҳ Ўз раҳматини фиръавндан узоқлаштирган, шу сабабдан фиръавн ўз қарорида собит эди.
            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[7] ? 'block' : 'none' }}
              onClick={() => handleSupClick(7)}
            >
              <p >
                <sup>7</sup>  «Бас, (Фиръавн лашкари) тонг отганда уларнинг орқасидан тушди. Икки жамоат бир-бир­ларини кўрган чоғида, Мусо­нинг одамлари: «Энди қўлга тушдик», де­ди­лар» (Шуаро 60-61).
              </p>
            </div>
            <p onClick={() => handleSupClick(7)}>
              Фиръавн лашкарлари саф-саф бўлиб яқинлашиб келаётган эди.<sup>7</sup> Буни кўрган Бани Исроил даҳшатга тушдилар. Фарёд чекиб, Оллоҳга дуо қила бошладилар.
            </p>
            <p>
              Кейин Мусо пайғамбарга: «Нега бизни чўлга олиб келдинг? Ўлдириш учунми? Нима, Мисрда қабристон топилмасмиди? Нима учун Мисрдан олиб чиқдинг? Бизни тинч қўй, шу ерда хизмат қилиб юраверамиз, деб айтган эдик-ку! Мисрда қул бўлиб юрганимиз, саҳрода ўлганимиздан минг марта яхши эди», деб шикоят қилдилар.
            </p>
            <p>
              «Қўрқманглар! — деди Мусо (а.с.). — Дадил бўлинглар! Оллоҳ сизларни қандай қутқаришига ўзингиз гувоҳ бўласиз. Бугун кўриб турган бу мисрликларни бошқа яна кўрмайсиз. Оллоҳнинг Ўзи сизлар учун жанг қилади. Сизлар фақат сабр қилиб, жим турсангиз бўлди».
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[8] ? 'block' : 'none' }}
              onClick={() => handleSupClick(8)}
            >
              <p >
                <sup>8</sup>  «Бас, Биз Мусога: “Асонг билан денгизни ур”, деб ваҳий қилдик» (Шуаро 63-оят).
              </p>
              <p className='MarginTop10px'>
                <sup>9</sup> Тоҳо сурасида: «Батаҳқиқ, Биз Мусога: “Сен бандала­рим ила кечаси йўлга туш, улар­га денгиз­дан қуруқ йўл оч, етиб олиш­ла­ри­дан хавф қилма ва қўрқма”, деб ваҳий қилдик», деб ёзилган (20:77).
              </p>
            </div>
            <p onClick={() => handleSupClick(8)}>
              Шунда Худодан Мусо пайғамбарга ваҳий келди: «Дод-фарёдни тўхтатинглар. Одамларга айт, йўлга тушсинлар. Сен асонгни кўтариб, денгизга узатиб, сувни бўл.<sup>8</sup> Шунда қуруқлик очилади, орасидан ўтиб кетасизлар», деди.<sup>9</sup>
            </p>
            <p>
              Ўша вақт булут устуни Исроил халқининг орқа тарафига — мисрликлар билан ибронийлар орасига ўтди ва тонг отгунча бир томонга ёруғлик бериб, иккинчи томонни қоронғу қилиб турди. Натижада фиръавннинг лашкарлари Бани Исроилга яқинлаша олмади.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> МИСРЛИКЛАРНИНГ МАҒЛУБИЯТГА УЧРАШИ </p>
          </div>

          <div className='main_text'>
            <p className="boldItalic">
              «Денгизни ўртадан бўлиб сизларни қутқариб, кўз ўнгингизда фиръавн одамларини ғарқ қилганимизни эсланглар!» (Қуръон, Бақара 50).
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[10] ? 'block' : 'none' }}
              onClick={() => handleSupClick(10)}
            >
              <p >
                <sup>10</sup> Абулфидо, “Ал-бидоя ван-ниҳоя”, 1-жилд, 271-бет.
              </p>
              <p className='MarginTop10px'>
                <sup>11</sup> Масъудий, “Аxбор уз-замон”, 251-бет.
              </p>
            </div>
            <p onClick={() => handleSupClick(10)}>
              Расулуллоҳ ҳассасини денгизга қаратиши билан Оллоҳ таоло шарқдан кучли бир шамол эстирди. Шамол денгизни иккига бўлиб, кечаси билан сувларни орқага суриб турди.<sup>10</sup> Денгиз бўлиниб ўртадан катта қуруқ йўл очилди<sup>11</sup> ва халқ денгизнинг нариги қирғоғига қараб йўлга тушди. Сувлар уларнинг ўнг ва чап томонида девордек тик турган эди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[12] ? 'block' : 'none' }}
              onClick={() => handleSupClick(12)}
            >
              <p >
                <sup>12</sup> Тавротда ёзилишича, Оллоҳ субҳанаҳу ва таоло ўша куни фиръавнни ҳам, унинг лашкарини ҳам қўрқувдан озод қилган. Шу сабабдан улар Оллоҳнинг шунча мўъжизаларини кўрсалар ҳам, қайсарлик қилиб Бани Исроилнинг ортидан денгизга кирган эдилар.
              </p>

            </div>
            <p onClick={() => handleSupClick(12)}>
              Саҳар чоғи фиръавннинг лашкарлари Бани Исроилнинг орқасидан таъқиб қилишга тушдилар.<sup>12</sup>

              Шунда Оллоҳ таоло олов устунидан Миср лашкарига ваҳшат солди. Араваларининг чархларини ҳам бузди.
            </p>
            <p>
              Буни кўрган мисрликлар додлаб: «Қочдик! Уларнинг худоси бизга қарши уруш қиляпти! Орқага қочамиз!» деб бақира бошладилар.
            </p>
            <p>
              Шунда Оллоҳ Мусо (а.с.) га: «Қўлингни денгиз томон узат: сувлар мисрликлар устига ағдарилсин», деди. Мусо (а.с.) қўлини денгиз томон узатдилар.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[13] ? 'block' : 'none' }}
              onClick={() => handleSupClick(13)}
            >
              <p >
                <sup>13</sup>  «Бас, Биз уни ва унинг аскарларини тутдик ва денгизга отдик. Энди золимларнинг оқи­ба­ти қандоқ бўлганига назар сол» (Қасас сураси 28:40).
              </p>
            </div>
            <p onClick={() => handleSupClick(13)}>
              Тонг отган пайт эди. Денгиз аввалги ҳолига қайтди. Мисрликлар қочар экан, тўфон уларни супуриб денгизга чўктирди. Фиръавннинг бутун лашкари, Бани Исроилнинг орқасидан кирган аравалару суворийлари сувга ғарқ бўлди.<sup>13</sup> Улардан ҳатто бир киши ҳам тирик қолмади. Исроил халқи эса денгизда очилган қуруқ йўлдан бехатар ўтаверди. Сувлар уларнинг ўнгу чап тарафида девордек қотиб турган эди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[14] ? 'block' : 'none' }}
              onClick={() => handleSupClick(14)}
            >
              <p >
                <sup>14</sup>  «Батаҳқиқ, Биз Мусо ва Ҳорунга неъмат бердик. Икковларига ва қавмларига катта ғамдан нажот бердик. Ва уларга нусрат бердик, бас, улар ғолиб бўлдилар» (Қуръон, 37:114-116).
              </p>
            </div>
            <p onClick={() => handleSupClick(14)}>
              Ҳа, Оллоҳ субҳанаҳу ва таоло шу тариқа фиръавннинг енгилмас лашкарини мағлуб қилди ва Ўз шукуҳ-азаматини ҳамма халқларга намойиш этди.<sup>14</sup>
            </p>
            <p>
              Шу тарзда Оллоҳ, ўша куни Яъқуб пайғамбарнинг наслини мисрликлардан озод қилди. Улар мисрликларнинг ўликлари денгиз соҳилида ётганини кўрдилар. Худонинг қудратига ўз кўзлари билан гувоҳ бўлдилар ва қалбларида Оллоҳдан қўрқиш пайдо бўлди — Оллоҳга ҳамда Унинг бандаси бўлган Мусо (а.с.) га иймон келтирдилар.
            </p>
            <p></p>
          </div>







          <article className='nav_arrows'>
            <div className='flex_it space_between'>
              <div style={{ marginLeft: '0px' }} onClick={() => navigate(-1)}>
                <div>
                  <span className='left_arrow_span'>&#8592;</span>
                </div>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' > орқага қайтиш </span>
                </div>
              </div>

              <div className='central_div'>
                <div>
                  <p>Қурбонлик сўйилиши</p>
                </div>
              </div>

              <div style={{ marginRight: '0px' }} onClick={() => navigate('/qisas-uzbek/adventures-in-desert')}>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' > Саҳрода </span>
                </div>
                <div>
                  <span className='right_arrow_span' >&#8594;</span>
                </div>
              </div>
            </div>

          </article>
        {/* <ThanksComponent /> */}
        </main >
        <aside> </aside>
      </div >
    </div >
  )
}

export default DeathOfFirstborn
