import React from 'react'
import UmightLike from '../../components/UmightLike'
import logo from '../../images/small_logo2.png'
import kamar from '../../images/kamar.png'
import './welcomePage.css'

import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../../components/LanguageSwitcher'
import ThanksComponent from '../../components/ThanksComponent'

const WelcomePage = () => {
  const { t } = useTranslation();

  return (
    <div className='minister-home  welcome_page'>
      <div className='topbar'>
        <div className='middle_part1'>
          <div className='icon_and_h2' >
            <h1>
            {t('Wel.MainHeading')}
              {/* W<span>e</span>lcom<span>e</span> pag<span>e</span> */}
            </h1>
            <LanguageSwitcher />
          </div>
        </div>
        <div className="middle_part2">
        </div>
      </div>

      <div className="separate_into_two_columns">
        <div className='sep_part1'>

          <div className='chapter_heading items-center' >
            <div className="kamar">
              <img src={kamar} alt="" />
            </div>
            <div className="img_container" style={{ padding: '7px' }}>
              <img src={logo} alt="" />
            </div>
            <div className='title_of_chapter '>
              <p> {t('Wel.WelcomeToOurOnline')}  </p>
              <p> {t('Wel.DiscipleshipPlatform')}</p>
            </div>
          </div>

          <div className='specific_for_this_page'>
            <div className='content'>
              <p>
              {t('Wel.P1')}
              </p>
              <p>
              {t('Wel.P2')}
              </p>
              <p className='for_middle_screens'>
              {t('Wel.P3')} <span className="red">{t('Wel.DevNotesTitle')} </span> {t('Wel.DevNotesDefinition')}
              </p>
              <p className='for_small_screens'>
              {t('Wel.P4')}
              </p>

              <ul>
                <div>
                  <li> <span className='bullet_point'>o</span> </li>
                  <li>
                    <span>{t('Wel.BlogPageTitle')}</span>{t('Wel.BlogPageDef1')}<span>{t('Wel.BookmarkTitle')}</span>{t('Wel.BlogPageDef2')}
                  </li>
                </div>
                <div>
                  <li> <span className='bullet_point'>o</span>  </li>
                  <li>   <span>{t('Wel.CommunitiesTitle')}</span>{t('Wel.CommunitiesDef1')}</li>
                </div>
                <div>
                  <li> <span className='bullet_point'>o</span>  </li>
                  <li>
                    <span>{t('Wel.LearningTitle')}</span>{t('Wel.LearningDef1')}<span>{t('Wel.ServantsTitle')}</span>{t('Wel.ServantsDef')}
                  </li>
                </div>
                <div>
                  <li> <span className='bullet_point'>o</span>  </li>
                  <li>
                    <span>{t('Wel.ResourcesTitle')}</span>{t('Wel.ResourcesDef')}
                  </li>
                </div>
                <div>
                  <li> <span className='bullet_point'>o</span>
                  </li>
                  <li>
                    <span>{t('Wel.MessagesTitle')}</span>{t('Wel.MessagesDef')}
                  </li>
                </div>
                <div>
                  <li> <span className='bullet_point'>o</span> </li>
                  <li>
                    <span>{t('Wel.ProfileTitle')}</span>{t('Wel.ProfileDef')}
                  </li>
                </div>
                <li>
                {t('Wel.P5')} </li>
              </ul>
            </div>
          </div>
          {/* <ThanksComponent /> */}
        </div>

        <aside>
          <div className="right_column_contain">
            <div className="right_column_posts">
              <h3>
              {t('Wel.RightColHead1')}
              </h3>
              <div className="spacer10px"></div>
              <p className='text-sm'>
              {t('Wel.RightColP1')}
              </p>
            </div>
            <div className="right_column_ads">
              <UmightLike
                h3={t('Wel.RightColAd1Head1')}
                titleName={t('Wel.RightColAd1Title1')}
                btnWord={t('Wel.RightColAd1ButtonWord1')} />
            </div>
          </div>
        </aside>
      </div>
    </div>
  )
}

export default WelcomePage