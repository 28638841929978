import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'
import ThanksComponent from '../../components/ThanksComponent';

const EXAMPLE = () => {

  const navigate = useNavigate();

  // const [search, setSearch] = useState('')


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 24;

    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      // console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  return (

    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">

          <div className='chapter_heading2'>
            <p> ЯЪҚУБ (А.С.) НИНГ ОТАСИДАН  </p>
            <p>БАРАКА ОЛГАНИ</p>
          </div>

          <div className='decor_line'>
            <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}


            >
              <p>
                <sup>1</sup>   Ибн Ҳабиб, “Китаб-ул мухаббар”, 296-бет; Масъудий, “Муруж уз-заҳаб”, 1-жилд, 47-бет.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)}>
              Исҳоқ (а.с.) қариб, кўзлари яхши кўрмай қолган эди.<sup>1</sup>  Кунлардан бир кун у киши тўнғич ўғли Ийсуни олдига чақирдилар: «Ўғлим!»

              «Лаббай, отажон», деди Ийсу (р.а.).
            </p>
            <p>

            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}


            >
              <p>
                <sup>2</sup>   У киши, Иброҳим (а.с.) га ваъда қилинган барака занжирини тўнғич ўғлим давом эттириши керак, деб ўйлар эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(2)}>
              Исҳоқ (а.с.): «Мана, мен қариб қолдим, қачон куним битишини билмайман, — дедилар. — Шунга камонингни олиб, мен учун ов овлаб кел. Кейин гўштидан мен яхши кўрган таомни пишир. Ўлишимдан олдин сенга Оллоҳнинг баракасини бермоқчиман».<sup>2</sup>
            </p>

            <p>
              Рафиқа Биби уларнинг суҳбатини эшитиб турган эди. Ийсу овга кетиши билан
              у дарров кичик ўғли Яъқубни чақириб деди:
            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}


            >
              <p>
                <sup>3</sup> Ийсу тўнғичлик ҳуқуқини аллақачон укаси Яъқубга сотиб қўйган эди. Шундай бўлса ҳам у отасига бу ҳақда ҳеч нарса демай ов овлашга кетган эди.
              </p>
            </div>

            <p onClick={() => handleSupClick(3)}>
              «Ўғлим! Отанг ҳозир Ийсуга:  “Менга ов овлаб кел. Мен яхши кўрган таомни пишир. Ўлишдан олдин Оллоҳнинг баракасини сенга бермоқчиман”, деди.   Энди гапларимни диққат билан эшит!
              Ҳозир тез бориб, сурувдан икки семиз улоқ олиб кел. Мен отанг яхши кўрган таомни пишираман.
              Сен уни отангга олиб кирасан. Eб ўлишдан олдин баракани сенга беради, Ийсуга<sup>3</sup>  эмас», деди.
            </p>

            <p className="vitalic">
              Эсингиздами, Оллоҳ субҳанаҳу ва таоло бир вақтлар Рафиқа Бибига: «Қорнингда икки эл бор, сендан икки алоҳида-алоҳида халқ туғилади. Бири иккинчисидан зўр келиб, каттаси кичигига хизмат қилади», деб ваъда берган эди. Бу икки эл — мана шу икки ака-укадан пайдо бўладиган халқлар. «Каттаси кичигига хизмат қилади» дегани эса, «барака занжири кичик ўғлингнинг насли орқали давом этади», деган маънода эди. Шу сабабдан Рафиқа Биби Исҳоқ (а.с.) нинг гапини эшитиб қаттиқ безовта бўлди.
            </p>

            <p>
              «Она! — деди Яъқуб (а.с.). — Акамнинг бадани юнгли, меники эса силлиқ.
              Мабодо отам мени ушлаб кўрса ёлғончи бўламан! Кейин: “Сенми мени аҳмоқ қиладиган!” деб менга барака ўрнига қарғиш беради!»
            </p>

            <p>
              «Ўша қарғиш менинг бўйнимга, — деди Рафиқа. — Бор, мен айтган гапни қил, улоқларни келтир».
            </p>

            <p>
              Яъқуб (а.с.) улоқларни келтириб сўйди. Рафиқа Биби Исҳоқ (а.с.) хуш кўрадиган таомни пиширди. Кейин уйдан Ийсунинг яхши кийимларини олиб, Яъқуб (а.с.) га кийдирди. Қўллари билан бўйнини эса улоқ териси билан ёпди. Сўнг таом билан нонни унинг қўлига тутқазди-да  отасининг олдига киргизди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <p>
              —Ота!
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Ҳа ўғлим? Кимсан, Ийсумисан, Яъқубмисан? — деб сўради отаси.
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Бу мен — тўнғич ўғлингиз Ийсуман, — деди Яъқуб отасига. — Мана, дуойингизни олай, деб ов гўштидан таом ҳозирладим. Тура қолинг, еволинг.
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Қандай қилиб мунча тез топиб келдинг? — деб ҳайрон бўлди отаси.
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Оллоҳ рўпара қилди», деб жавоб берди Яъқуб (а.с.).
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Яқинроқ кел-чи ўғлим, ушлаб кўрай қани, ростдан ҳам Ийсумисан?
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <p>
              Яъқуб (а.с.) отасига яқинлашди. Отаси уни сийпаб: «Овоз Яъқубнинг овози, лекин қўллари Ийсуники», деди.
            </p>

            <p>
              Яъқуб (а.с.) нинг қўлларига тери ёпилган, акаси Ийсуникидай юнгли эди. Шунинг учун Исҳоқ (а.с.) уни Ийсу деб ўйладилар, лекин яна бир марта:
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Сен ростдан Ийсумисан? — деб сўрадилар.
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Ҳа, Ийсуман, — деди Яъқуб (а.с.).
            </p>
            <p>Шундан кейин отаси: «Таомингни олиб кел. Кейин сенга барака бераман», дедилар.</p>
          </div>


          <div className='paragraph_heading'>
            <p>
              БИР МАРТА БЕРИЛАДИГАН БАРАКА
            </p>
          </div>

          <div className='main_text'>
            <p>
              Еб-ичиб бўлганларидан кейин ҳазрати Исҳоқ: «Яқинроқ кел, ўғлим, мени ўп!» дедилар. Яъқуб (а.с.) яқин бориб отасини ўпди. Исҳоқ (а.с.) унинг кийимини ҳидлаб, уни дуо қилиб деди: «Ўғлимнинг ҳиди, Оллоҳ баракалаган даштнинг ҳидидай!
            </p>
            <p></p>
          </div>
          <div className='main_text'>
            <p>
              Худо ҳамиша экинларингга ёмғир берсин,
            </p>
          </div>
          <div className='main_text'>
            <p>
              ерларинг ҳосилдор, ғалланг кўп, узуминг мўл бўлсин.
            </p>
          </div>
          <div className='main_text'>
            <p>
              Халқлар сенга хизмат қилсин, эллар сенга итоат этсин.
            </p>
          </div>
          <div className='main_text'>
            <p>
              Биродарларингга бошлиқ бўлгайсан,
            </p>
          </div>
          <div className='main_text'>
            <p>
              Қариндошларинг сенга таъзим қилгай.
            </p>
          </div>
          <div className='main_text'>
            <p>
              Сени лаънатлаган лаънат олсин,
            </p>
          </div>
          <div className='main_text'>
            <p>
              Сенга барака тилаган барака топсин!»
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <p>
              Яъқуб (а.с.) отасининг дуосини олиб, энди чиқиб кетган пайтда акаси Ийсу овдан қайтиб келди  ва емак тайёрлаб, отасининг олдига кирди: «Ота, туринг. Ов гўштидан сиз яхши кўрадиган таомни пишириб келдим, еб менга дуо беринг».
            </p>
            <p>
              —Нима?! Кимсан сен? — деб сўради Исҳоқ (а.с.).
            </p>
          </div>

          <div className='main_text'>
            <p>   —Тўнғич ўғлингиз Ийсуман.</p>
            <p></p>
          </div>

          <div className='main_text'>
            <p>
              Буни эшитиб Исҳоқ (а.с.) қаттиқ безовта бўлдилар: «Боя таом пишириб келган ким эди? Мен еб унга барака бердим. Энди барака уники бўлди», дедилар титраб.
            </p>
            <p>Ийсу (р.а.) буни эшитиб, аччиқланганидан ўзини қаерга қўйишини билмай қолди. Кейин: «Ота, отажон, менга ҳам барака беринг, отажон», деб ялина бошлади.</p>
            <p>
              «Уканг ҳийла билан сенинг баракангни олиб кетди, болам», деди Исҳоқ (а.с.).
            </p>

            <p>
              Ийсу (р.а.) эса: «Унга Яъқуб деб бекорга от қўйишмаган, — дер эди додлаб. — У иккинчи марта мени алдади. Олдин тўнғичлик ҳуқуқимни мендан олиб қўйди. Энди баракамни ҳам олиб кетибди. Менга битта ҳам барака қолдирмадингизми?»
            </p>

            <p>
              «Мен уни сенга бошлиқ қилдим. Ҳамма ака-укаларинг сенга қул бўлади, дедим. Уни ғаллаю узум билан таъминладим. Сен учун энди нима ҳам қилоламан, ўғлим?» деди ҳазрати Исҳоқ.
            </p>

            <p>
              «Нахотки сизда фақат биттагина барака бўлса? Менга ҳам барака беринг!» деб йиғларди у.
            </p>

            <p>
              «Серҳосил ерлардан бенасиб бўласан.
              Шамширинг соясида яшаб, уканг хизматида юрасан. Лекин озод бўлишни хоҳласанг, кишанларини узиб ташлайсан», деди Исҳоқ (а.с.).
            </p>
            <p>
              Ийсу (р.а.) укасини жуда ёмон кўриб қолган эди: “Майли, отам ҳали тирик экан, индамайман. Бироқ вақти келсин, ўлдирмай қўймайман”, дерди у ўзига-ўзи.
            </p>
            <p></p>
          </div>

          <article className='nav_arrows'>
            <div className='flex_it space_between' >
              <div style={{ marginLeft: '0px' }} onClick={() => navigate(-1)}>
                <div>
                  <span className='left_arrow_span'>&#8592;</span>
                </div>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' > орқага қайтиш </span>
                </div>
              </div>

              <div className='central_div'>
                <div>
                  <p>Бараканинг ўғирланиши</p>
                </div>
              </div>

              <div style={{ marginRight: '0px' }} onClick={() => navigate('/qisas-uzbek/jacob')}>
                <div className='indicator'>

                  <span className=' bottom_arrows_for_qisas' > Яъқуб (а.с.) қиссаси </span>
                </div>
                <div>
                  <span className='right_arrow_span' >&#8594;</span>
                </div>
              </div>
            </div>
          </article>
          {/* <ThanksComponent /> */}
        </main>
        <aside>
        </aside>
      </div>
    </div >
  )
}

export default EXAMPLE
