import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'
import ThanksComponent from '../../components/ThanksComponent';

const EXAMPLE = () => {

  const navigate = useNavigate();


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 24;

    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      //     console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  return (

    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">
          <div className='chapter_heading2'>
            <p> Яъқуб алайҳис-саломнинг башорати  </p>
          </div>

          <div className='decor_line'>
            <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
          </div>

          <div className='main_text'>
            <p>
              Қаҳатчилик кундан-кунга кучайиб бораётган эди. Мисрда ҳам, Канъонда ҳам одамлар очликдан қийналаётган эдилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>  ЮСУФ (А.С.) НИНГ ФИРЪАВНГА ҚИЛГАН ХИЗМАТИ </p>
          </div>

          <div className='main_text'>
            <p>
              Ҳазрати Юсуф мисрликлар ва канъонликларга ғалла сотиб, уларнинг бор пулини фиръавннинг хазинасига ўтказдилар. Пуллари тугагандан кейин, одамлар Юсуф (а.с.) нинг олдига келиб: «Бизга дон беринг. Кўз олдингизда ўлиб кетмайлик. Бошқа пулимиз қолмади», деб ялиндилар.  «Пулингиз тугаган бўлса, ҳайвонларингизни олиб келинг, эвазига дон оласиз», дедилар ҳазрати Юсуф.
              Кейин улар от-эшакларини, қўй-сигирларини ҳазрати Юсуфнинг олдига олиб кела бошладилар. Бир йил шундай ўтди.
            </p>

            <p>
              Кейинги йил — қаҳатчилик ҳануз шиддат билан давом этаётган эди — одамлар яна келиб, Юсуф (а.с.) га: «Ҳазратим, аҳволимизни кўриб турибсиз: пулимиз йўқ, сотишга молимиз ҳам қолмади.  Кўз олдингизда ўлиб кетмайлик. Бизларни фиръавнга қул қилинг, ерларимизни олинг. Эвазига озуқа берсангиз бас. Шунда ўзимиз ҳам тирик қоламиз, ерларимиз ҳам хароб бўлмайди», дейишди.
            </p>

            <p>
              Шу билан ҳазрати Юсуф Мисрдаги ҳамма ерларни фиръавннинг мулкига айлантирдилар.  Миср халқи бир бошдан фиръавнга қул бўлди. Сотилмай қолган ягона жой руҳонийлар ери эди. Чунки руҳонийлар фиръавн томонидан таъминланар, ерларини сотишга муҳтож эмас эдилар.
            </p>
            <p>
              «Мен сизларни ҳам, ерларингизни ҳам фиръавн учун сотиб олдим, — дедилар ҳазрати Юсуф Миср халқига. — Энди сизларга экиш учун уруғлик бераман.  Кейин ҳар йили ҳосил йиғиш вақтида ҳосилнинг бешдан бирини фиръавнга топширасизлар, қолган тўрт қисмини эса егулик учун ва уруғлик учун ишлатасизлар».
            </p>
            <p>
              «Сиз бизга яхшилик қилиб, ҳаётимизни сақлаб қолдингиз. Сиз нима десангиз шу. Фиръавнга хизмат қилишга тайёрмиз», дейишди улар.  Ўша-ўша, ҳазрати Юсуфнинг “Мамлакатда етиштириладиган ҳосилнинг бешдан бир қисми фиръавнга солиқ сифатида ажратилади”, деб жорий қилган қонуни Мисрда ўз кучини йўқотгани йўқ. Фақат руҳонийларнинг ери бундан мустасно эди.
            </p>
            <p>
              Исроил (а.с.) нинг фарзандлари эса Жўшан ўлкасига жойлашиб, бойлик томондан ҳам, одамларининг сони жиҳатидан ҳам тез суръатда кўпаяётган эдилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ЯЪҚУБ (А.С.) НИНГ ЮСУФГА ВАСИЯТИ
            </p>
          </div>

          <div className='main_text'>
            <p>
              Ҳазрати Яъқуб Мисрга келгандан кейин яна ўн етти йил яшадилар. (Ҳаммаси бўлиб бир юз қирқ етти йил умр кўрдилар.) Дунёдан кўз юмиш вақти яқинлашар экан, ўғли Юсуфни олдларига чақириб: «Агар мендан рози бўлсанг қўлингни соним тагига қўй. Сизни Мисрда эмас, Канъонда,  ота-боболаримиз ётган жойга қўяман, деб қасам ич», дедилар.
            </p>

            <p>
              «Айтганингиздай қиламан, ота», дедилар Юсуф (а.с.). «Йўқ, қасам ич!» дедилар ҳазрати Яъқуб. Юсуф (а.с.) қасам ичдилар. Кейин ҳазрати Яъқуб, ҳассасига таяниб ўрнидан қўзғалдилар-да Оллоҳ таолога сажда қилдилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              АФРОЙИМ БИЛАН МАНСИ БОБОСИ ҲУЗУРИДА
            </p>
          </div>

          <div className='main_text'>
            <p>
              Орадан ҳеч қанча вақт ўтмай ҳазрати Юсуфга: «Отангизнинг мазаси йўқ», деган хабар келди. Шунда у киши ўғиллари Манси билан Афройимни олиб отасини кўришга бордилар.
            </p>

            <p>
              Яъқуб (а.с.) ўғли келганини эшитиб, бор қувватини йиғиб ётган жойидан туриб ўтирдилар  ва: «Қодири мутлақ Худо Канъоннинг Луз шаҳрида менга аён берган эди. Мени баракалаб,  сенинг наслингни кўпайтираман, сендан халқлар жамоаси пайдо бўлади, мана шу юртни сенга ва сенинг авлодларингга абадий мулк қилиб бераман, деб айтган эди, — дедилар. — Мен сенинг бу ерда туғилган икки ўғлингни, Афройим билан Мансини, ўзимга ўғил қилиб олмоқчиман. Худди Роавбин билан Шимъундай улар ҳам мендан қоладиган меросга шерик бўладилар.  Кейинги фарзандларинг ўзингники: улар Афройим билан Мансининг улушидан мерос оладилар.  Мен буни онанг Роҳийланинг хотираси учун қиляпман. Биз Байнул-наҳрайндан қайтиб келаётганимизда онанг Афротга етмасдан йўлда вафот этган. Уни ўша ерда, Афрот йўлида сағанага қўйганман».
            </p>
            <p>
              Кейин невараларини кўриб: «Булар ким?» деб сўрадилар. Чунки қариликдан кўзлари яхши кўрмай қолган эди.
            </p>
            <p>
              «Булар Манси билан Афройим, Худо менга Мисрда ато этган ўғиллар», деб жавоб бердилар ҳазрати Юсуф. «Олдимга олиб кел, мен уларни дуо қилай», дедилар  Яъқуб (а.с.) невараларини ўпиб бағрига босдилар:  «Мен сени яна кўраман деб ҳеч ўйламаган эдим. Қара, Худо менга ҳатто фарзандларингни ҳам кўришни насиб этди», дедилар.
            </p>
            <p>
              Ҳазрати Юсуф ерга эгилиб отасига таъзим қилдилар.  Сўнгра катта ўғли Мансини отасининг ўнг томонига, Афройимни бўлса чап томонига ўтқаздилар.
            </p>
            <p>
              Лекин Яъқуб (а.с.) қўлларини бир-бирининг устидан ўтказиб, ўнг қўлини Афройимнинг, чап қўлини бўлса Мансининг бошига қўйдилар.  Ва ҳазрати Юсуфга дуо бериб дедилар: «Ота-боболарим Иброҳим ва Исҳоқ сиғинган, умрим бўйи менга ғамхўрлик қилган,  мени ҳар қандай ёмонликлардан асраб келаётган Оллоҳ болаларингга барака берсин. Бобом Иброҳимнинг, отам Исҳоқнинг ва менинг исмим мана шу болаларнинг вужудида яшасин. Бола-чақаси, фарзандлари зиёда бўлсин!»
            </p>

            <p>
              Ҳазрати Юсуф отаси ўнг қўлини Афройимнинг бошига қўйганини ёқтирмай, уни Афройимнинг бошидан олиб Мансининг бошига қўймоқчи бўлдилар: «Йўқ, ота, каттаси мана бу, ўнг қўлингизни буёққа қўйинг», дедилар.
            </p>
            <p>
              Лекин Яъқуб (а.с.) унинг гапини қабулламай: «Биламан, ўғлим, биламан,  – дедилар.  – Мансидан ҳам катта бир қавм вужудга келади, лекин укаси Афройимнинг уруғи ундан катта бўлади. Унинг наслидан кўп қавмлар пайдо бўлади».
            </p>
            <p>
              Шундоқ қилиб ўша куни, ҳазрати Яъқуб бу икки неварага барака тилаб: «Исроил халқи бир-бирини дуо қилганда, Худо сени Афройимдек ва Мансидек саодатманд қилсин, деб отингизни доим тилга олади», дедилар. У киши шу тариқа Афройимнинг Мансидан юқори бўлишини кўрсатдилар.
            </p>

            <p>
              Кейин ҳазрати Юсуфга: «Мана, энди бу дунё билан хайрлашадиган вақтим келди. Лекин Оллоҳ сизлар билан бирга бўлади. Сизларни яна Канъонга, ота-боболарингиз юртига олиб боради», дедилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ЯЪҚУБ (А.С.) НИНГ ЎҒИЛЛАРИ ҲАҚИДА ҚИЛГАН БАШОРАТИ
            </p>
          </div>

          <div className='main_text'>
            <p className="boldItalic">
              «Иброҳим ўша (миллат)ни болаларига васият қилди. Яъқуб ҳам: «Эй болаларим, албатта, Оллоҳ сизларга динни танлади, сизлар мусулмон бўлмасдан туриб ўлмайсиз», деди» (Бақара 132).
            </p>
            <p>
              Кейин Яъқуб алайҳис-салом ҳамма ўғилларини олдига чақиртириб:  «Ўғилларим, қулоқ солинглар, мен сизларга келажакда нималар бўлишини айтмоқчиман», дедилар.
            </p>
            <p>
              «Роавбин! Сен менинг тўнғич ўғлимсан. Кучу қудратимнинг илк мевасисан. Қудрат ва мақомда ҳаммадан юқори эдинг.  Тоғ сувидек беқарорсан. Номусимни булғаганинг учун мен сени тўнғичлик ҳаққидан маҳрум қиламан.
            </p>
            <p></p>
          </div>


          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}


            >
              <p>
                <sup>1</sup>  Бу гаплар кейинчалик айнан сўзма-сўз амалга ошади.
              </p>
            </div>

            <p onClick={() => handleSupClick(1)}>
              Эй Шимъун билан Лобий! Иккалангиз бир хил одамсизлар. Иккалангиз бераҳм, бешафқатсизлар.  Мен сизларнинг кенгашингизда иштирок этмайман, разил ниятларингизга шерик бўлмайман. Сизлар ғазаб устида одам ўлдирдингиз, ҳўкизларнинг пайини кесиб, завқ олдингиз.  Лаънат бундоқ бераҳм ғазабингизга! Лаънат бундай бешафқат қаҳрингизга! Мен сизларнинг наслингизни бутун Исроилга пароканда қиламан.<sup>1</sup>
            </p>

            <p>
              Яҳузо! Сен жангларда душманларингни мағлуб қиласан. Барча ака-укаларинг сени мақтайди, сенга таъзим қилади.  Сен шикордан қайтиб дам олиб ётган баҳайбат шер кабисан. Ким уни безовта қилишга журъат қила олади?  Ҳамма халқлар итоат этгувчи Ворис келгунича салтанат асоси сенинг қўлингда бўлади.  Фаровон ҳаёт кечирасан. Еганинг олдингда, емаганинг ортингда.  Ҳамма нарсанг етарли бўлганидан фарзандларинг соғлом, бақувват, чиройли бўлишади.
            </p>
            <p>
              Забулун, сен денгиз соҳилида яшайсан. Кемаларга бандаргоҳ бўласан. Чегараларинг Сайдун тарафга қараб чўзилади.
            </p>
            <p>
              Ясокир, сен бақувватсан,  лекин ёқтирган нарсанг учун ҳар кимга ҳам қул бўлиб эшакдек ишлашга тайёрсан.
            </p>
            <p>
              Дон, сен ўз қабилангни ўзинг бошқарасан.  Қабиланг кичкина бўлса ҳам душманнинг додини беради.
            </p>
            <p>Ё Худованд, Сенинг нажотингга мунтазирман!</p>
            <p>
              Жод, босқинчилар ҳужум қилиб, сени мағлуб қилади, лекин сен кейин уларни ҳайдаб чиқарасан.
            </p>
            <p>
              Ашир! Сенинг ризқинг мўл бўлади. Шоҳона таомлар тановул қиласан.
            </p>
            <p>
              Нафтоли! Сен эркинликда юрган, фарзандлари гўзал бўлган оҳуга ўхшайсан.
            </p>
            <p>
              Юсуф! Чашма бўйида ўсган ток ҳар тарафга новда отгандай, сенинг наслинг ҳам ер юзида кўп бўлади. Душманлар сенга ҳужум қилиб, бошингга ўқлар ёғдирди, лекин Яъқубнинг қодир Худоси соясида, Исроилнинг Чўпони ва суянган Тоғи паноҳида сенинг камонинг мустаҳкам, қўлларинг бақувват турди.  Ота-боболарим Худоси сенга ёрдам берсин! Қодир Оллоҳ сени баракаласин! Ер-осмондан бошингга Ўз баракаларини ёғдирсин! Фарзандларингни кўп, ризқларини баракали қилсин!  Отангнинг дуолари мангу тоғлардан ҳам улуғ. Эй Юсуф, сен акаларинг орасида шаҳзодасан, бу баракалар сенинг бошингга тож бўлиб кийилади.
            </p>
            <p>
              Бинямин! Сен йиртқич бўрисан. Саҳарда ўлжангга тиш ботирасан, кечқурун ўлжангни тақсим қиласан».
            </p>
            <p>
              Исроил алайҳис-салом мана шу сўзлар билан ўғилларига барака улашди. Ўн икки ака-уканинг ҳар бири отасидан ўзига муносиб бўлган дуони олди. Исроилнинг ўн икки қабиласи мана шу ўн икки ака-укадан вужудга келган.
            </p>
          </div>


          <div className='paragraph_heading'>
            <p>
              ҲАЗРАТИ ЯЪҚУБНИНГ ВАФОТИ
            </p>
          </div>

          <div className='main_text'>
            <p className="boldItalic">
              «Яъқубга ўлим ҳозир бўлиб, у ўз болаларига: «Мендан кейин нимага ибодат қиласизлар?» деганида, улар: «Сенинг илоҳингга ва оталаринг Иброҳим, Исмоил ва Исҳоқларнинг илоҳига, ёлғиз илоҳга ибодат қиламиз ҳамда биз Унга мусулмон бўлувчимиз», деган эдилар» (Бақара 133).
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}


            >
              <p>
                <sup>2</sup> Яқут, "Мужам ул булдон", 2-жилд, 212- бет.
              </p>
            </div>

            <p onClick={() => handleSupClick(2)}>
              Кейин Яъқуб (а.с.) ўз ўғилларига бундоқ деб васият қилдилар: «Мен энди бу дунёдан кетяпман. Бориб ота-боболарим қаторига қўшиламан. Сизлар мени оталарим ёнига, Канъондаги Макфала ғорига қўйинглар. У жойни бобом Иброҳим, экинзори билан бирга ҳитий Эфрундан сотиб олган эди. Боболарингиз Иброҳим билан Исҳоқ ҳам, эналарингиз Соро билан Рафиқа ҳам ўша ерга қўйилган. Мен Лайёни ҳам ўша ерга қўйдим.  Бобом Иброҳим бу ғорни экинзори билан бирга ҳитийлардан ана шу мақсадда сотиб олган эди», дедилар.<sup>2</sup>
            </p>

            <p>
              Ўғилларига васият қилиб бўлгандан сўнг, тўшагига узалиб жон бердилар.
            </p>

            <p>
              Ҳазрати Юсуф йиғлаб ўзини отасининг устига ташладилар. Қучоқлаб юзларидан ўпдилар.  Кейин жасадни мўмиёлаш учун табибларни чақирдилар.
            </p>

            <p>
              (Мисрда майитни мўмиёлаш учун қирқ кун вақт кетар эди.) Етмиш кун Мисрда Яъқуб (а.с.) учун мотам тутдилар.  Мотам кунлари тугагандан сўнг, Юсуф (а.с.) фиръавннинг аъёнларига: «Бориб менинг номимдан фиръавнга айтинглар: отам менга қасам ичириб, “Ўлганимда мени Канъонга, ота-боболарим ётган жойга қўй”, деб тайинлаган эдилар. Рухсат беринг, отамни Канъонга олиб борай. Маросим тугагач қайтиб келаман», дедилар.
            </p>
            <p>
              Фиръавн розилик берди: «Борсин, отасига берган ваъдасини бажарсин», деди.
            </p>
            <p>
              Шундай қилиб, ҳазрати Юсуф ҳам, ака-укалари ҳам оилаларини олиб, оталарини сўнгги йўлга узатиш учун йўлга тушдилар.  (Ёш болалар ва мол-ҳоллар Жўшанда қолди.)
            </p>

            <p>
              Фиръавннинг аъёнлари, Мисрнинг нуфузли одамлари ҳам улар билан бирга кетдилар. Аравалар, отлиқлар ва одамлардан ташкил топган катта бир карвон Канъонга қараб йўлга чиқди.
            </p>

            <p>
              Ўрдун дарёсининг нариги томонига, Атод хирмонига етиб борганларида, йиғи қилиб, етти кун аза тутдилар.  Атодда яшайдиган канъонликлар мотамни кўриб: “Мисрликлар бу ерда катта мотам тутди”, деб ўша жойга Обел-Мисройим (яъни, мисрликлар мотами) деган от қўйишди.
            </p>

            <p>
              Ҳазрати Яъқубнинг ўғиллари оталарининг васиятини бажо келтирдилар:  у кишини Канъонга олиб бориб Мумарраҳ яқинидаги Макфала ғорига қўйдилар.  Кейин ҳаммалари Мисрга қайтишди.
            </p>
          </div>


          <div className='paragraph_heading'>
            <p>
              АКАЛАРИНИНГ ХАВОТИРИ
            </p>
          </div>

          <div className='main_text'>
            <p>
              Оталарининг вафотидан сўнг, Юсуф (а.с.) нинг акалари: “Юсуф ҳали ҳам юрагида кек сақлаб юрган бўлса-я! У энди биздан ўч олса керак?!” деб ташвишга тушдилар.
            </p>
            <p>
              Кейин, ҳазрати Юсуфга: «Отам кўз юмишдан олдин, Юсуфга айтинглар, акалари ёмонлик қилган бўлса ҳам, уларнинг гуноҳини кечирсин, деб васият қилган эдилар. Бизни кечир! Ахир биз ҳам отамиз сиғинган Худонинг қулларимиз-ку!» деб хабар жўнатдилар.
            </p>
            <p>
              Бу гапни эшитиб ҳазрати Юсуфнинг кўзлари ёшга тўлди.
            </p>
            <p>
              Кейин акаларининг ўзлари Юсуф (а.с.) нинг олдига келиб: «Биз сенинг қулларингмиз», деб унинг оёғига йиқилдилар.
            </p>
            <p>
              «Нимага мендан қўрқасизлар? — деди Юсуф (а.с.) — Мен Худоманми, сизларни ҳукм қилсам, жазоласам?  Тўғри, сизлар менга ёмонлик қилишни истадингиз, лекин Худо сизларнинг ёмон ниятингизни яхшиликка айлантирди. Қанча-қанча одамларнинг ҳаётини сақлаб қолиш учун мени бу мансабга кўтарди.  Қўрқманглар. Мен ўзим сизларга, бола-чақаларингизга ғамхўрлик қиламан», дедилар.
            </p>
            <p>
              Шундан сўнг акаларининг кўнгли тинчланди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ЮСУФ (А.С.) НИНГ ОЛАМДАН ЎТИШИ
            </p>
          </div>

          <div className='main_text'>
            <p className="boldItalic">
              «Эй Раббим, ҳақиқатда менга мулк бердинг ва менга тушларнинг таъбирини ўргатдинг. Эй осмонлару ерни яратган зот! Сен дунёю охиратда менинг валиййимсан. Мени мусулмон ҳолимда вафот этдиргин ва солиҳларга қўшгин» (Қуръон, 12:101).
            </p>
            <p>
              Ҳазрати Юсуф ҳам, акалари ҳам Мисрда қолдилар. Юсуф алайҳис-салом бир юз ўн йил яшаб,  Афройимнинг ўғиллари ва невараларини, Мансининг ўғли Мохирнинг фарзандларини кўриб, дунёдан кўз юмдилар.
            </p>
            <p>
              Ўлишдан олдин, акаларига: «Менинг ўлимим яқин. Лекин Оллоҳ албатта сизларга ёрдам беради, сизларни оталаримиз Иброҳим, Исҳоқ ва Яъқубга ваъда қилган юртга олиб боради», деб айтдилар.
            </p>
            <p>
              Кейин уларга қасам ичириб: «Худо сизларни Канъонга қайтарганда, менинг суякларимни ҳам ўзингиз билан олиб кетинг», дедилар.
            </p>
            <p>
              Шундай қилиб, Юсуф алайҳис-салом, бир юз ўн ёшида вафот этдилар. Вафот этганларидан сўнг, жасадини мўмиёлаб Мисрда бир сандиққа солдилар.
            </p>

            <p className="vitalic">
              Қуръони каримнинг Юсуф сураси мана бу сўзлар билан хотима топган:  <span className="boldItalic">
                «Бу ғайб хабарлардан бўлиб, уни сенга ваҳий қилмоқдамиз. Улар макр қилиб, ишлари ҳақида гап бириктирганларида сен уларнинг олдиларида бўлмаган эдинг. Одамларнинг кўплари, гарчи сен жуда қизиқсанг ҳам, мўмин эмаслар... Улар осмонлару ердаги қанчадан-қанча оят-белгилар қаршисидан юз ўгирган ҳолларида ўтарлар. Кўплари эса, Оллоҳга мушрик бўлган ҳолларидагина иймон келтирадилар, холос. Улар ўзларига Оллоҳдан қамраб олувчи азоб келишидан ёки сезмай турганларида қиёмат ногаҳон келиб қолишидан омондамилар?!»
              </span>  (12:102-103, 105-107).
            </p>
            <p></p>
          </div>




          <article className='nav_arrows'>
            <div className='flex_it space_between'>
              <div style={{ marginLeft: '0px' }} onClick={() => navigate(-1)}>
                <div>
                  <span className='left_arrow_span'>&#8592;</span>
                </div>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' > орқага қайтиш </span>
                </div>
              </div>

              <div className='central_div'>
                <div>
                  <p>Фарзандлар ҳақида башорат</p>
                </div>
              </div>


              <div style={{ marginRight: '0px' }} onClick={() => navigate('/qisas-uzbek/musa')}>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' > Мусо (а.с.)</span>
                </div>
                <div>
                  <span className='right_arrow_span' >&#8594;</span>
                </div>
              </div>
            </div>
          </article>
         {/* <ThanksComponent /> */}
        </main>
        <aside> </aside>
      </div>
    </div >
  )
}

export default EXAMPLE
