import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'
import ThanksComponent from '../../components/ThanksComponent';

const WhoAmI = () => {

  const navigate = useNavigate();


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 1000;

    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      //     console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (

    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">
          <div className='chapter_heading2'>
            <p> Уни ўйлантирган савол: «Мен кимман?» </p>
          </div>

          <div className='decor_line'>
            <p>
              &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;
            </p>
          </div>


          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p >
                <sup>1</sup> <span className="vitalic">Қуръони каримда ёзилишича Фиръавннинг аёли ўз яқинларига:  <span className="boldItalic">«(Бу бола) мен учун ҳам, сен учун ҳам кўз қувончидир. Уни ўлдирманглар. Шоядки, у бизга фойда берса ёки уни бола қилиб олсак»</span>, деган экан (Қасас сураси 28:9).   У киши ўзининг кимлигини билар эдилар. Эмизиб катта қилган онасидан эшитганми, ё унга ёмонлик тилаб, фиръавн саройидан маҳрум қилишни истаган одамлар айтганми, бизга номаълум. Маълум бўлган нарса шуки, у киши ўз халқининг тарихини, Оллоҳ таолонинг аҳди ва ваъдаларини билганлар.</span>
              </p>
            </div>
            <p onClick={() => handleSupClick(1)} >
              Бола бир оз улғайгандан сўнг, онаси уни фиръавннинг қизига топширди. Фиръавннинг қизи уни ўзига ўғил қилиб олди.
              “Мен уни сувдан тортиб олдим”, деб исмини Мусо қўйди. Йиллар ўтди. Мусо (а.с.) вояга етдилар.<sup>1</sup>
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>  МИСРДАН ҚОЧИШ </p>
          </div>

          <div className='main_text'>
            <p >
              Кунлардан бир кун, ҳазрати Мусо ўз халқини кўриш учун борган пайтида, бир мисрликнинг бир ибронийни калтаклаётганини кўриб қолдилар. Ён-атрофга қараб ҳеч кимни кўрмадилар ва ҳалиги мисрликни ўлдириб, жасадини қумга яширдилар.
            </p>

            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}
            >
              <p >
                <sup>2</sup>      «(Кунларнинг бирида Мусо) шаҳарга унинг аҳолиси ғафлатда бўлган пайтда кирган эди, икки киши урушаётганини кўрди. Бири ўзининг жамоатидан ва бири душманидан эди. ... Мусо мушт уриб уни ўлдириб қўйди. (Сўнг пушаймон бўлиб) деди: «Бу шайтоннинг ишидан. Албатта у йўлдан ургувчи очиқ душмандир» (Қасас сураси).
              </p>

              <p className='MarginTop10px'>
                <sup>3</sup> Қасас сураси 19-оят.
              </p>

            </div>
            <p onClick={() => handleSupClick(2)}>
              Эртаси куни у киши яна ўз халқининг олдига бордилар. Бу сафар икки иброний бир-бири билан жанжаллашар эди. «Уни нега уряпсан? — деб сўрадилар ҳазрати Мусо. — Ахир биз бир-биримизга биродармиз-ку!»<sup>2</sup>
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <p onClick={() => handleSupClick(2)}>
              «Сенинг нима ишинг бор? Ё <span className="boldItalic">“кеча бировни ўлдиргандек мени ҳам ўлдирмоқчимисан?”</span>» деб жавоб берди у.<sup>3</sup>   Шунда ҳазрати Мусо, мисрликни ўлдирганим ҳаммага маълум бўпти, деб қўрқиб кетдилар.

            </p>
            <p className='vitalic'>
              Ҳақиқатан ҳам, бу воқеа ҳамма ёққа овоза бўлган эди. Қуръони каримда айтилиши­ча: <span className='boldItalic'>
                «...шаҳарнинг нариги четидан бир киши шошиб келиб: «Эй Мусо, аниқки, аъёнлар сени ўлдириш учун тил бириктирмоқдалар. Бас, чиқиб кет! Албатта, мен сенга насиҳат қил­гув­чи­лар­дан­ман», деди. Бас, хавфсираб, аланглаган ҳолда у ер­дан: «Эй Раббим, менга золим қавмлардан нажот бергин», деб чиқди» </span> (28:20-21).
            </p>
            <p>
              Фиръавн, бўлган воқеадан хабар топгач, одамларига: “Мусони қатл қилинглар”, деб фармон беради. Мусо (а.с.) Мадян юртига қочиб кетадилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>  ҲАНИФ АРАБЛАР ҚАБИЛАСИДА </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[4] ? 'block' : 'none' }}
              onClick={() => handleSupClick(4)}
            >
              <p >
                <sup>4</sup>     Мадян руҳонийсининг исми ҳа­қи­да ихтилоф бор. Ҳасан ал-Басрий ва Молик ибн Анас у кишининг исмини Шуайб де­ган­лар. Ибн Аббос (р.а.) ва Абу Убайда (р.а.) лар «Ятрун» деб айтганлар.
              </p>
            </div>
            <p onClick={() => handleSupClick(4)}>
              Ўша пайтда Мадянда Шуайб исмли бир руҳоний яшарди. У кишининг еттита қизи бўлган. Қизлари ҳар куни отасининг қўйларини суғориш учун қудуқ бошига ҳайдаб келишар, қўйларни энди суғормоқчи бўлганларида бошқа чўпонлар келиб, қудуқни эгаллаб олар эди.
              <sup>4</sup>
            </p>
            <p>
              Бу сафар ҳам қизлар қўйларни ҳайдаб қудуқ бошига келдилар. Айни шу пайтда ҳазрати Мусо Мисрдан келиб, қудуқ бўйида дам олиб ўтирган эдилар.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[5] ? 'block' : 'none' }}
              onClick={() => handleSupClick(5)}
            >
              <p >
                <sup>5</sup> Қасас сурасининг 23-24 оятларини кўринг.
              </p>
            </div>
            <p onClick={() => handleSupClick(5)}>
              Қизлар қудуқдан сув тортиб, охурларга қуяётганда яна чўпонлар келиб қолишди. «Ҳей, қани, қочинглар, — дейишди улар қизларга. — Олдин биз суғорамиз. Ол қўйингни нарёққа!» Шунда ҳазрати Мусо ўринларидан туриб, чўпонларни орқага сурдилар. Кейин қизларга қўйларни суғоришга ёрдам бердилар.<sup>5</sup>
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[6] ? 'block' : 'none' }}
              onClick={() => handleSupClick(6)}
            >
              <p >
                <sup>6</sup>     Табарий, “Тарих”, 1-жилд, 205-бет; Салабий, “Ароис”, 174-бет; Ибн-и Асокир, “Тарих”, 6-жилд, 322-бет; Ибн-и Асир, “Комил”, 1-жилд, 176-бет.
              </p>
            </div>
            <p onClick={() => handleSupClick(6)}>
              Қизлар уйга келгач, Шуайб (а.с.) улардан: «Нима бўлди? Бугун нега тез қайтдинглар?» деб сўрадилар.<sup>6</sup>
            </p>
            <p>«Бир мисрлик бизга ёрдам берди. Чўпонлардан ҳимоя қилди. Кейин қудуқдан сув тортиб, қўйларимизни ҳам суғорди», дейишди улар. </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[7] ? 'block' : 'none' }}
              onClick={() => handleSupClick(7)}
            >
              <p >
                <sup>7</sup> «Сўнгра Мадян аҳли ила йилларга қолдинг» (Тоҳо, 20:40).
              </p>
            </div>
            <p onClick={() => handleSupClick(7)}>
              «Қани у? Нега уйга чақирмадингиз? Боринг, айтиб келинг, бизникида меҳмон бўлсин», дедилар Шуайб (а.с.). Шу билан, ҳазрати Мусо шу хонадонда яшай бошладилар.<sup>7</sup>
            </p>
            <p>
              Кейин Шуайб (а.с.) у кишига ўзининг Сафира деган қизини никоҳлаб берди.  Вақт-соати келиб Сафира Биби Мусо (а.с.) га ўғил туғиб берди. “Бегона юртда ғариб бўлдим”, деб ҳазрати Мусо ўғлига Гаршун, яъни “ғариб” деб от қўйган эканлар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> Оллоҳ БАНИ ИСРОИЛНИ ҚУТҚАРМОҚЧИ </p>
          </div>

          <div className='main_text'>
            <p>
              Орадан кўп йил ўтди. Миср подшоҳи ҳам оламдан кўз юмди. Бироқ Бани Исроил ҳамон қулликда азоб чекар эди. Ниҳоят улар Оллоҳга оҳу нола қила бошладилар. Шунда Оллоҳ уларни озод қилишга қарор қилди, чунки У раҳмон ва раҳимдур. У мозийда Иброҳим, Исҳоқ ва Яъқуб пайғамбарлар билан аҳд қилган эди.
            </p>

            <p className="vitalic">
              Агар эсингизда бўлса, Оллоҳ таоло уларга учта нарсани ваъда қилган:
            </p>

            <p className="vitalic">
              ~биринчидан, У Иброҳим пайғамбарга сенинг авлодларинг кўп бўлади, деб ваъда берган эди. Дарҳақиқат, Исроил халқи охирги 400 йил ичида етмиштадан бир неча миллион одамга кўпайди.
            </p>
            <p className="vitalic">
              ~Иккинчи ваъда ер ҳақида эди: Оллоҳ субҳанаҳу ва таоло: “Авлодларинг тўрт юз йил бегона элда келгинди бўлиб яшайди. Қулликда жабр-зулм кўради. Кейин Мен уларни у юртдан олиб чиқаман ... Мана шу ерни сенинг авлодларингга мерос қилиб бераман”, деган эди.
            </p>
            <p className="vitalic">
              ~Учинчи ваъда — Иброҳим (а.с.) нинг зуррияти орқали ер юзидаги ҳамма халқлар баракага эришиши ҳақида эди. Оллоҳ азза ва жалл: «Менга итоат этганинг учун ер юзидаги ҳамма миллатларга Мен сенинг зурриятинг орқали барака бераман», деб ваъда берган эди.
            </p>
            <p className="vitalic">
              Кўриб турибсизки, биринчи ваъда Мусо пайғамбар даврига келиб амалга ошди. Яъқуб пайғамбарнинг насли катта бир миллатга айланди. Энди эса Оллоҳ таоло Ўзининг иккинчи ваъдасини амалга оширмоқчи эди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ЁНАЁТГАН БУТА </p>
          </div>

          <div className='main_text'>

            <p className='boldItalic'>
              «Бас, қачонки у (олов яқинига) келгач: «Эй Мусо», деган нидо эшитилди. «Мен сенинг раббингман. Энди кавушларингни еч...» <span className='vitalic'>(Тоҳо сураси, 11-12).</span>
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[8] ? 'block' : 'none' }}
              onClick={() => handleSupClick(8)}
            >
              <p >
                <sup>8</sup> Сино тоғига.
              </p>
            </div>
            <p onClick={() => handleSupClick(8)}>
              Мусо (а.с.) қайнотасининг қўйларини боқар эди. Бир куни у сурувни чўлнинг нариги томонига, Туводаги тоққа<sup>8</sup> қараб ҳайдадилар. Шунда Оллоҳ жалла жалолаҳу ўша ерда у кишига олов зоҳир қилди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[9] ? 'block' : 'none' }}
              onClick={() => handleSupClick(9)}
            >
              <p >
                <sup>9</sup> Абулфарад ибн-и Жавзий. “Табсира”, 1-жилд, 219-220 бетлар.
              </p>
              <p className='MarginTop10px'>
                <sup>10</sup> Қуръони карим, 20:10.
              </p>
            </div>
            <p onClick={() => handleSupClick(9)}>
              Ҳазрати Мусо кўрдиларки, бир бута ёняпти, лекин ёниб адо бўлмаяпти! «Во ажабо, нима учун бу бута ёнади, лекин адо бўлмайди?» деб ҳайрон   бўлдилар<sup>9</sup> ва <span className="boldItalic">  «аҳлига: “Кутиб туринглар, мен олов кўрдим, шояд ундан чўғ келтирсам ёки олов бошида ҳидоят топсам”, деб» бутага яқинлашдилар.</span><sup>10</sup>
            </p>
            <p>
              Шунда оловдан: «Мусо! Мусо!» деган овоз келди. «Лаббай!» дедилар Мусо (а.с.).
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[11] ? 'block' : 'none' }}
              onClick={() => handleSupClick(11)}
            >
              <p >
                <sup>11</sup> Қуръони каримда ҳам “сенинг (Яъқубнинг) илоҳинг, Иброҳимнинг илоҳи, Исмоил ва Исҳоқнинг илоҳи”, деган ибора бор (Бақара 133). Тавротда бу ибора Оллоҳ таолонинг ушбу пайғамбарлар билан қилган аҳдини таъ­кид­ла­моқда.
              </p>

              <p className='MarginTop10px'>
                <sup>12</sup>  Оллоҳ (с.в.т.) қиёматда ҳам ерни Ўз Нури билан ёритади: «Ва ер Рабби нури ила ёришди. Китоб қўйилди. Пайғамбарлар ва гувоҳлар келтирилди...» (Зумар 39:69).
              </p>
            </div>
            <p onClick={() => handleSupClick(11)}>
              «Тўхта, бошқа яқинлаша кўрма! Чориқларингни еч, чунки сен муқаддас жойдасан. Мен аждодинг Иброҳим, Исҳоқ ва Яъқубнинг илоҳиман», деди садо.<sup>11</sup>
            </p>
            <p>
              Мусо алайҳис-салом Оллоҳнинг нурига қарашдан қўрқиб юзларини беркитдилар.<sup>12</sup>
            </p>
            <p>
              «Менинг халқим азоб-уқубатда. Назоратчилар дастидан фарёд чекмоқда. Мен Раҳмонман. Халқимни мисрликлар чангалидан қутқариш вақти келди, — деди Оллоҳ таоло. —             Мен сизларни Канъонга — сут ва асал ўлкасига олиб бораман.  Тайёрлан. Сени Мисрга, фиръавннинг олдига юбораман. Менинг халқимни, Бани Исроилни сен Мисрдан олиб чиқасан».
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[13] ? 'block' : 'none' }}
              onClick={() => handleSupClick(13)}
            >
              <p >
                <sup>13</sup>     Табарий, “Тарих”, 1-жилд, 206-бет; Ибн-и Асир, “Комил”, 1-жилд, 178-бет.
              </p>
            </div>
            <p onClick={() => handleSupClick(13)}>
              Оллоҳ таоло раҳмдил ва раҳмондир. Бани Исроилнинг қулликда азоб чекаётганини кўриб Оллоҳнинг раҳми келган эди.<sup>13</sup>
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>«Сен фиръавнга бор, чунки у туғёнга кетди»<sup>14</sup></p>

          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[14] ? 'block' : 'none' }}
              onClick={() => handleSupClick(14)}
            >
              <p >
                <sup>14</sup>       Қуръони карим, Тоҳо сураси, 20:24.
              </p>
            </div>
            <p onClick={() => handleSupClick(14)}>
              «Мен кимманки, фиръавннинг олдига борсам?! — деди ҳазрати Мусо. — Мен қандай қилиб Бани Исроилни Мисрдан олиб чиқаман?»
            </p>
            <p>
              «Мен сен билан бирга бўламан, сени қўллайман. — деди Худо. — Сизлар Мисрдан чиқиб, мана шу ерга келасизлар. Мана шу тоғда Менга ибодат қиласизлар. Сени Мен юборганимнинг далили шу».
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[15] ? 'block' : 'none' }}
              onClick={() => handleSupClick(15)}
            >
              <p >
                <sup>15</sup> Исроил халқи анча йилдан бери Мисрда қулликда яшар, шу сабабдан оламлар рабби — Оллоҳ ҳақида тушунчаси оз эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(15)}>
              Шунда Мусо (а.с.) яна савол бериб дедилар: «Агар Бани Исроил: “Бизнинг Худомиз ким? У нима қила олади?” деб сўраса, нима дейман?»<sup>15</sup>
            </p>
            <p>
              Оллоҳ таоло деди: «Мен ал-Қайюмман. Менинг бу исмим насллар-аро зикр қилинур. Мисрга бор, оқсоқолларни йиғ. Уларга: “Оталаримиз Иброҳимнинг, Исҳоқнинг ва Яъқубнинг илоҳи ал-Қайюм менга зоҳир бўлди”, де.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[16] ? 'block' : 'none' }}
              onClick={() => handleSupClick(16)}
            >
              <p >
                <sup>16</sup>   Қуръони каримда: «Яхшилик қилганга (неъмат) батамом бўлиши учун, ҳар бир нарса батафсил бўлиши учун, ҳидоят ва раҳмат бўлиши учун Биз Мусога Китоб ато этдик, токи (Бани Исроил) Раббилари билан учрашмоққа иймон келтирсалар», деган оят бор (Анъом сураси 154).
              </p>
            </div>
            <p onClick={() => handleSupClick(16)}>
              “Мисрдаги аҳволингизни кузатиб турибман. Берган ваъдамга кўра, Мен сизларни бу азоб уйидан олиб чиқиб, сут ва асал ўлкасига олиб бораман, деб айтди”, де. Улар гапингни қабул қилади. Кейин фиръавннинг олдига бориб: “Оллоҳ биз билан учрашмоқчи.  Уч кунлик йўл юриб чўлга чиқишимизни, ўша ерда Унга атаб қурбонлик қилишимизни буюрди”, деб айтасизлар.<sup>16</sup>
            </p>
            <p>
              Лекин фиръавн кетишингизга рухсат бермайди. Кейин Мен Ўз қудратимни ва мўъжизаларимни намойиш қиламан, мисрликлар устига офатлар ёғдираман. Ўшанда у сизларни қўйиб юборишга мажбур бўлади», деди.
            </p>
            <p></p>
          </div>



          <article className='nav_arrows'>
            <div className='flex_it space_between'>
              <div style={{ marginLeft: '0px' }} onClick={() => navigate(-1)}>
                <div>
                  <span className='left_arrow_span'>&#8592;</span>
                </div>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' > орқага қайтиш </span>
                </div>
              </div>

              <div className='central_div'>
                <div>
                  <p>«Мен кимман?» </p>
                </div>
              </div>

              <div style={{ marginRight: '0px' }} onClick={() => navigate('/qisas-uzbek/three-miracles-of-Moses')}>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' > Учта мўъжиза </span>
                </div>
                <div>
                  <span className='right_arrow_span' >&#8594;</span>
                </div>
              </div>
            </div>

          </article>
          {/* <ThanksComponent /> */}
        </main >
        <aside> </aside>
      </div >
    </div >
  )
}

export default WhoAmI
